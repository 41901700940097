import HMLogo from "../components/HMLogo";
import styles from "./LoadingScreen.module.css";
import { CircularProgress } from "@mui/material";

export default function LoadingScreen() {
  return (
    <div className={styles.loadingScreenContainer}>
      <div>
        <HMLogo className={styles.iconImage} />

        <div className={styles.spinner}>
          <CircularProgress />
        </div>
      </div>
    </div>
  );
}
