import ReactGA from "react-ga";
const GA_TRACKING = "UA-68529912-4";

const report = process.env.NODE_ENV === "production";

if (report) {
  ReactGA.initialize(GA_TRACKING);
}

export function reportPageView(page: string) {
  if (report) {
    ReactGA.pageview(page);
  }
}

export function reportEvent(event: string) {
  if (report) {
    ReactGA.event({
      category: "Click",
      action: event,
    });
  }
}
