import Button from "@mui/material/Button";

interface OfferButtonProps {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
}

export default function OfferButton(props: OfferButtonProps) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  );
}
