import AppRouter from "./AppRouter";
import { StyledEngineProvider } from "@mui/material/styles";
import WithGlobalTheme from "./components/MuiTheme";
import "./util/AnalyticUtils";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <WithGlobalTheme>
        <AppRouter />
      </WithGlobalTheme>
    </StyledEngineProvider>
  );
}

export default App;
