import styles from "./OfferFooter.module.css";

export default function OfferFooter() {
  return (
    <div>
      <div className={styles.footnotes}>
        <div>חיוב בשל ביטול הרצאה:</div>
        <div>
          בהתראה של פחות משבעה ימי עסקים - 50% מהעלות. בהתראה של פחות משני ימי
          עסקים - 100% מהעלות.
        </div>
        <div>הצעת מחיר זו תקפה לשלושה חודשים מיום הוצאתה.</div>
      </div>

      <div className={styles.contactInformation}>
        <a href="https://c-point.co.il/" className={styles.field}>
          c-point.co.il |
        </a>
        <a href="tel:0523366313" className={styles.field}>
          טלפון: 052-3366313 |
        </a>
        <span className={styles.field}>פקס: 072-2446260 |</span>
        <a href="mailto:hanan@c-point.co.il" className={styles.field}>
          מייל: hanan@c-point.co.il
        </a>
      </div>
    </div>
  );
}
