import styles from "./PageNotFound.module.css";
import HMLogo from "./HMLogo";
import { useEffect } from "react";
import { reportPageView } from "../util/AnalyticUtils";

export default function PageNotFound() {
  useEffect(() => {
    reportPageView("/v1/notFount");
  }, []);

  return (
    <div className={styles.pageNotFound}>
      <HMLogo className={styles.iconImage} />
      <br />
      <br />
      <div>אופס! הקישור לא תקין 😔</div>
      <br />
      <div>ניתן ליצור קשר עם המשרד</div>
      <br />
      <div>
        <span className={styles.linkField}>טלפון:</span>
        <a href={"tel:+972523366313"}>052-3366313</a>
      </div>
      <div>
        <span className={styles.linkField}>מייל:</span>
        <a href={"mailto:hanan@c-point.co.il"}>hanan@c-point.co.il</a>
      </div>
      <div>
        <span className={styles.linkField}>אתר:</span>
        <a href={"https://www.c-point.co.il"}>www.c-point.co.il</a>
      </div>
    </div>
  );
}
