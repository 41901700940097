import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { prefixer } from "stylis";

export const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      light: "#C43184",
      main: "#7b2c93",
      dark: "#531d64",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#fbdb05",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const cacheRtl = createCache({
  key: "muirtl",
  // prefixer is the only stylis plugin by default, so when
  // overriding the plugins you need to include it explicitly
  // if you want to retain the auto-prefixing behavior.
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function WithGlobalTheme(props: any) {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </CacheProvider>
  );
}
